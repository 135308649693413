<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-button
      class="btn btn-gradient-primary mb-1 mr-50 col-md-2"
      @click="$router.go(-1)"
    >
      <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
    </b-button>
    <b-button
      class="btn btn-gradient-success mb-1 ml-25 mr-50 col-md-2"
      @click="Submit()"
    >
      {{ $t('save') }}
    </b-button>
    <b-button
      v-if="items.status === 'waiting'"
      class="btn btn-gradient-success mb-1 ml-25 mr-50 col-md-2"
      :disabled="isDisabled"
      @click="Confirm('approve')"
    >
      <i class="far fa-check mr-50" /> {{ $t('approve') }}
    </b-button>
    <b-button
      v-if="items.status === 'waiting'"
      class="btn btn-gradient-danger mb-1 ml-25 mr-50 col-md-2"
      :disabled="isDisabled"
      @click="Confirm('restore')"
    >
      <i class="far fa-ban mr-50" /> {{ $t('cancelOrder') }}
    </b-button>

    <b-row class="match-height">
      <b-col md="6">

        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('details') }}
            </h3>
          </div>

          <div class="p-2">
            <b-row>
              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('orderNumber') }} :
                  </template>

                  <p class="mt-50 font-weight-bolder">
                    {{ items.itemnumber_text }}
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('customerCode') }} :
                  </template>
                  <div class="mt-50 font-weight-bolder">
                    <router-link
                      :to="{name : 'admin-employee-edit', params: {id: items.uid}}"
                    >
                      {{ items.username }}
                    </router-link>
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('status') }} :
                  </template>
                  <p
                    v-if="items.status === 'waiting'"
                    class="mt-50 mb-0 badge badge-light-primary"
                  >
                    {{ $t('pendingReview') }}
                  </p>
                  <p
                    v-if="items.status === 'success'"
                    class="mt-50 mb-0 badge badge-light-success"
                  >
                    {{ $t('operationCompleted') }}
                  </p>
                  <p
                    v-if="items.status === 'restore'"
                    class="mt-50 mb-0 badge badge-light-secondary"
                  >
                    {{ $t('cancelled') }}
                  </p> <span
                    v-if="items.status === 'restore'"
                    class="text-danger"
                  >*{{ $t('canceledBy') }} {{ items.approveby }}</span>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('key-46') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ Commas(items.amount) }} ฿
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('transactionDate') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ time(items.created_at) }}
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('transferEvidence') }} :
                  </template>
                  <b-form-file
                    id="h-file1"
                    v-model="fileadmin"
                    accept=".jpg, .png, .jpeg"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="fileImage"
                  />
                  <span class="mt-2 d-flex justify-content-center">
                    <span
                      v-if="fileadminimg"
                      class="preview-image-containerv2 mr-2 d-flex justify-content-center"
                    >
                      <!-- :src="fileadminimg" -->
                      <img
                        :src="GetImg('Filewithdraw',fileadminimg)"
                        alt="Preview"
                        class=""
                        @click="showPicture(fileadminimg)"
                      >
                      <div class="preview-image-overlays p-0">
                        <i
                          class="fas fa-times-circle cursor-pointer"
                          @click="deleteImageorder()"
                        />
                      </div>

                      <vue-image-lightbox-carousel
                        ref="lightbox"
                        :show="showLightbox"
                        :images="images"
                        :show-caption="false"
                        @close="closeBox()"
                      />
                    </span>
                    <!-- Filewithdraw -->
                  </span>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('remarks') }} :
                  </template>
                  <b-form-textarea
                    id="h-remark"
                    v-model="remark"
                    type="text"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </div>
        </div>
      </b-col>

      <b-col md="6">

        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('key-48') }}
            </h3>
          </div>

          <div class="p-2">
            <b-row>
              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('bank') }} :
                  </template>

                  <p class="mt-50 font-weight-bolder">
                    <img
                      :src="`${items.path_photo}`"
                      height="30"
                      class="mr-50 rounded-sm"
                    >
                    {{ items.bankname }}
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('accountName') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ items.cus_bank }}
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('accountNumber') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    {{ items.acc_no }}
                  </p>
                </b-form-group>
              </b-col>

            </b-row>
          </div>
        </div>
      </b-col>

      <b-col md="12">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('relatedAmountList') }}
            </h3>
          </div>

          <div class="pl-2 pr-2">
            <b-table
              responsive="sm"
              :fields="fieldsRelevant"
              :items="Transations"
              show-empty
            >
              <template #empty="">
                <b-table-empty-slot>
                  <div class="text-center my-2">
                    <img
                      src="@/assets/images/anan-img/svg/doc_empty.svg"
                      alt="empty"
                      height="60px"
                    >
                    <p class="text-muted">
                      No Data
                    </p>
                  </div>
                </b-table-empty-slot>
              </template>

              <template #cell(amount)="data">
                <span :style="getAmountStyle(data.item.type, data.item.type_amount)">
                  {{ getAmountPrefix(data.item.type, data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿
                </span>
              </template>
            </b-table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormFile,
  BTable,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    BTable,
    BFormFile,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      isActive: true,
      file: null,
      parcelCode: null,
      remark: null,
      Transaction: [],
      parcelList: [
        {
          value: '1',
          text: '1',
        },
        {
          value: '2',
          text: '2',
        },
        {
          value: '3',
          text: '3',
        },
      ],

      ID: this.$route.params.id,
      items: [],
      fileadmin: null,
      fileadminimg: null,
      Transations: [],
      itemBankList: [],
      isDisabled: false,
    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'created_at', label: this.$t('transactionDate'), thStyle: { width: '20%' }, formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'detail', label: this.$t('details'), thStyle: { width: '60%' } },
        {
          key: 'amount', label: this.$t('quantity'), thStyle: { width: '20%' }, formatter: value => `${this.Commas(value.toFixed(2))} ฿`,
        },
      ]
    },
  },
  mounted() {
    this.getData()
    this.getTransations()
  },
  methods: {
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    async getData() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/Withdraw/ViewData', { params })
        this.items = res
        this.fileadminimg = this.items.file_slip
        this.remark = this.items.remark_admin
        // console.log(res)
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },
    Submit() {
      this.$bvModal.msgBoxConfirm('บันทึกข้อมูล?', {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: this.items._id,
            file_slip: this.fileadminimg,
            remark_admin: this.remark,
          }
          this.$http.post('/Withdraw/UpdateData', obj)
            .then(() => {
              this.getData()
              this.Success(this.$t('key-260'))
              this.fileadminimg = null
              this.fileadmin = null
            })
            .catch(err => {
              this.SwalError(err)
              console.log(err)
            })
        }
      })
    },
    Confirm(type) {
      this.isDisabled = true
      if (type === 'approve') {
        this.showtext = 'อนุมัติ'
      } else if (type === 'restore') {
        this.showtext = 'คืนเงิน'
      }
      this.$swal({
        title: 'แจ้งเตือน ?',
        // eslint-disable-next-line no-undef
        text: `ยืนยันที่จะ ${this.showtext} หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (type === 'approve') {
            this.approve(this.items)
          } else if (type === 'restore') {
            this.restore(this.items)
          }
        } else {
          this.isDisabled = false
          this.show = false
          this.$swal('ยกเลิก', `ยกเลิกการ${this.showtext}`, 'error')
        }
      })
    },
    approve(val) {
      this.show = true
      const formData = val
      this.$http
        .post('/Withdraw/UpdateWithdraw', formData)
        .then(response => {
          if (response.data.status) {
            this.isDisabled = false
            this.show = false
            this.getTransations()
            this.getData()
            this.username = null
            this.amount = 0
            this.Success('ถอนเครดิต สำเร็จ')
          }
        })
        .catch(error => {
          this.show = false
          this.isDisabled = false
          this.SwalError(error.response.data.message)
        })
    },
    restore(val) {
      this.show = true
      const formData = val
      this.$http
        .post('/Withdraw/UpdateRestore', formData)
        .then(response => {
          if (response.data.status) {
            this.show = false
            this.getTransations()
            this.getData()
            this.username = null
            this.amount = 0
            this.Success('คืนเงิน สำเร็จ')
            this.isDisabled = false
          }
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
          this.isDisabled = false
        })
    },
    async getTransations() {
      try {
        const params = {
          ref_id: this.ID,
        }
        const { data: res } = await this.$http.get('/transactions/show/ref', { params })
        if (res.success) {
          this.Transations = res.data
        }

        // console.log(res)
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },

    fileImage(event) {
      if (event) {
        this.fileadmin = event.target.files
        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.fileadmin[0]
        if (!filepath) {
          this.fileadminimg = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.fileadminimg = e.target.result
            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('Filewithdraw', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    getAmountStyle(amount, typeAmount) {
      if (amount === 21) {
        if (typeAmount) {
          return { color: 'green' }
        }
        return { color: 'red' }
      }
      if ([1, 8, 11, 12, 17, 16].includes(amount)) {
        return { color: 'green' }
      }
      if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(amount)) {
        return { color: 'red' }
      }
    },
    getAmountPrefix(value, typeAmount) {
      if (value === 21) {
        if (typeAmount) {
          return '+'
        }
        return '-'
      }
      if ([1, 8, 11, 12, 17, 16].includes(value)) {
        return '+'
      }
      if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(value)) {
        return '-'
      }
    },
    deleteImageorder() {
      this.$bvModal.msgBoxConfirm('ต้องการลบรูปภาพหรือไม่ ?', {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          this.fileadminimg = null
          this.fileadmin = null
          this.items.file_slip = null
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
